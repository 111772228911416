import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable";
import resources from "../../services";
import config from '../../constants';
import Icon from '../../ui/Icon';

export default function CustomerPage() {

    async function onUpdate(data, callback) {


        let res = await resources.seller.create({ user: data.id })
        if (res.status === 201) {
            callback()
        }
    }

    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Clients</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item "><Link className="text-muted"
                                        to="/dashboard">Accueil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Clients</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="col mt-5">
                        <DataTable column={[
                            { label: "Prenom", field: "lastname" },
                            { label: "Nom", field: "firstname" },
                            { label: "Email", field: "email" },
                            { label: "Numéro de téléphone", field: "phone" },
                            { label: "Role", field: "roles", render: [0] },
                        ]}
                            element={"customer"}
                            resource={resources.customer}
                            action={(data, callback) => {
                                return <li><button type="button" onClick={() => onUpdate(data, callback)} class="btn btn-outline-success dropdown-item"> Passer a vendeur </button>
                                </li>
                            }}
                        />
                    </div>
                </main>
            </div>
        </div>

    </div>)
}


