import React from 'react';
export class ComponentToPrint extends React.PureComponent {
    render() {
        return (
            <div className="">
                {this.props.children}
            </div>
        );
    }
}
