import Axios from "../Axios";

const command = {
    create(data) {
        return Axios.post('/command', data)
    },
    read(limit = 10, page = 1) {
        return Axios.get('/command?&limit=' + limit + "&page=" + page)
    },
    pending(limit = 10, page = 1) {
        return Axios.get('/command?status=1&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/command/' + id)
    },
    update(id, data) {
        return Axios.put('/commandes/' + id, data);
    },
    updateItem(id, data) {
        return Axios.put("/items/" + id, data);
    },
    deleteItem(id) {
        return Axios.delete("/items/" + id);
    },
    delete(id) {
        return Axios.put('/command/' + id)
    },
}

export default command;
