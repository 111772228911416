import React from "react";
import PropTypes from "prop-types";

function Button(props) {
    const {onLoad, children, title} = props;
    return (
        <>
            {onLoad ? (
                <button {...props} disabled>
          <span
              className="spinner-border spinner-border-sm mr-1"
              role="status"
              aria-hidden="true"
          />
                    {title}
                </button>
            ) : (
                <button {...props}>{children}</button>
            )}
        </>
    );
}

Button.propTypes = {
    onLoad: PropTypes.bool,
    title: PropTypes.string,
};

Button.defaultProps = {
    onLoad: false,
    title: "",
};

export default Button;
