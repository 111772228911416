import Axios from "../Axios";

const vitrine = {
    create(data) {
        return Axios.post('/vitrine', data)
    },
    read(limit = 10, page = 1) {
        return Axios.get('/vitrine?active=1&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/vitrine/' + id)
    },
    update(id, data) {
        return Axios.post('/vitrine/' + id, data)
    },
    delete(id) {
        return Axios.put('/vitrine/' + id, {
            "active": false
        })
    }
}

export default vitrine;
