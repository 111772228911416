import React, { useState, useEffect } from 'react'
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { Link, useHistory } from "react-router-dom";
import resources from "../../services";
import Button from "../../ui/Button";
import Select from 'react-select'
import Icon from '../../ui/Icon';

export default function Create() {
    const [loader, setLoader] = useState(false),
        history = useHistory(),
        [sellers, setSellers] = useState([]),
        [seller, setSeller] = useState()


    async function onGet() {

        let cus = await resources.category.read(1000)
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setSellers(a)
        }

    }

    useEffect(() => {
        onGet();
    }, [])

    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function onCreate(e) {
        e.preventDefault()
        let formData = new FormData(e.target)
        if (seller) {
            formData.append("types", seller)
        }

        setLoader(true)
        let res = await resources.tag.create(formData)
        if (res.status === 201) {
            history.goBack()
        } else {
        }
        setLoader(false)
    }

    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <form onSubmit={onCreate}>


                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>Nouveau tag</h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link className="text-muted"
                                            to="/dashboard">Accueil</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page"><Link
                                            className="text-muted" to="/setting/tags">Tagues</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Nouveau tag</li>
                                    </ol>
                                </nav>
                                <Button className="btn btn-app-default btn-sm"
                                    type="submit" onLoad={loader}><Icon name="save" color="white" /> Enregistré</Button>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">
                            <div className="col-lg-6 ">
                                <div className="card">
                                    <div className="card-body">


                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1" className="form-label">Nom <span
                                                style={{ color: "red" }}>*</span></label>
                                            <input type="text" required className="form-control" id="exampleInputEmail1"
                                                name="name" />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1"
                                                className="form-label">Categorie <span
                                                    style={{ color: "red" }}>*</span></label>
                                            <Select onChange={(e) => setSeller(e.value)} options={sellers} />
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </form>
            </div>
        </div>

    </div>)
}


