const config = {
    server: {
        localhost: "https://servertest.triantashop.com",
        web: "https://servertest.triantashop.com"

    },
    app: {
        name: "TRIANTA"
    },
    theme: {
        color: {
            default: "#000"
        }
    }
}
export default config
