import customer from "./customer"
import tag from "./tag"
import vitrine from "./vitrine"
import article from "./article"
import user from "./user"
import auth from "./auth"
import seller from "./seller"
import command from "./command"
import chart from "./chart"
import category from "./category"
import report from "./report"
import event from "./event"
import feature from "./feature"



const resources = {
    auth,
    customer,
    article,
    tag,
    user,
    vitrine,
    seller,
    command,
    chart,
    category,
    report,
    event,
    feature
};
export default resources;
