import React, { useEffect, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link, useLocation } from "react-router-dom";
import Sell from "./Sell";
import Stock from "./Stock";
import Best from "./Best";
import { ComponentToPrint } from './ComponentToPrint';

const ReportPage = () => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    useEffect(() => {
    }, [query.get("tab")]);


    return (
        <div>
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div className="mb-5">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <nav className="nav">
                                        <Link
                                            className={
                                                query.get("tab") == "sell"
                                                    ? "nav-link  border-bottom-default"
                                                    : "nav-link text-muted"
                                            }
                                            to="/reports?tab=sell"
                                        >
                                            <i className="bi-bookmark" /> Rapport de vente
                                        </Link>
                                        <Link
                                            className={
                                                query.get("tab") == "stock"
                                                    ? "nav-link  border-bottom-default"
                                                    : "nav-link text-muted"
                                            }
                                            to="/reports?tab=stock"
                                        >
                                            <i className="bi-bookmark" /> Rapport de stock
                                        </Link>
                                        <Link
                                            className={
                                                query.get("tab") == "best_seller"
                                                    ? "nav-link  border-bottom-default"
                                                    : "nav-link text-muted"
                                            }
                                            to="/reports?tab=best_seller"
                                        >
                                            <i className="bi-bookmark" /> Rapport des produits les mieux vendu
                                        </Link>

                                    </nav>
                                </div>
                                {/* <div>
                                    <button className="btn btn-app-default w-100 float-right"
                                        onClick={handlePrint}><i className="bi bi-printer mr-1" />Imprimer
                                    </button>
                                </div> */}
                            </div>

                        </div>
                        <div className="col">
                            {/* <ComponentToPrint ref={componentRef}> */}
                                {query.get("tab") == "sell" && <Sell />}
                                {query.get("tab") == "stock" && <Stock />}
                                {query.get("tab") == "best_seller" && <Best />}
                            {/* </ComponentToPrint> */}
                        </div>

                    </main>
                </div>
            </div>
        </div>
    );
};

export default ReportPage;
