import Axios from "../Axios";

const chart = {
    create(data) {
        return Axios.post('/chart', data)
    },
    read(limit = 10, page = 1) {
        return Axios.get('/article?active=1&limit=' + limit + "&page=" + page)
    },
    online(limit = 10, page = 1) {
        return Axios.get('/article?is_active=1&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/article/' + id)
    },
    update(id, data) {
        return Axios.put('/products/' + id + "?_method=PUT", data)
    },
    delete(id) {
        return Axios.put('/article/' + id, {
            "active": false
        })
    },
    addStock(data) {
        return Axios.post("/stocks", data);
    },

}

export default chart;
