import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import LoginPage from './pages/login/LoginPage';
import ArticlePage from './pages/Article/ArticlePage';
import Dashboard from './pages/Dashboard/Dashboard';
import CreateArticle from './pages/Article/CreateArticle';
import EditArticle from './pages/Article/EditArticle';
import ShopPage from './pages/Shop/ShopPage';
import CreateShop from './pages/Shop/CreateShop';
import EditShop from './pages/Shop/EditShop';
import CustomerPage from './pages/Customer/CustomerPage';
import CommandPage from './pages/Command/CommandPage';
import ViewCommand from './pages/Command/ViewCommand';
import ReportPage from './pages/Report';
import Category from './pages/Category';
import Event from './pages/Event';
import CreateCategory from './pages/Category/Create';
import CreateEvent from './pages/Event/Create';
import EditCategory from './pages/Category/Update';
import Tag from './pages/Tag';
import CreateTag from './pages/Tag/Create';
import EditTag from './pages/Tag/Update';
import Feature from './pages/Feature';
import CreateFeature from './pages/Feature/Create';


function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        {/*Read*/}
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/articles" component={ArticlePage} />
        <Route exact path="/shops" component={ShopPage} />
        <Route exact path="/customers" component={CustomerPage} />
        <Route exact path="/commands" component={CommandPage} />
        <Route exact path="/events" component={Event} />

        <Route exact path="/reports" component={ReportPage} />
        <Route exact path="/setting/categories" component={Category} />
        <Route exact path="/setting/tags" component={Tag} />
        <Route exact path="/setting/features" component={Feature} />

        {/*Create*/}
        <Route exact path="/article/create" component={CreateArticle} />
        <Route exact path="/shop/create" component={CreateShop} />
        <Route exact path="/feature/create" component={CreateFeature} />
        <Route exact path="/event/create" component={CreateEvent} />
        <Route exact path="/setting/category/create" component={CreateCategory} />
        <Route exact path="/setting/tag/create" component={CreateTag} />

        {/*Edit*/}
        <Route exact path="/article/edit/:id" component={EditArticle} />
        <Route exact path="/shop/edit/:id" component={EditShop} />
        <Route exact path="/category/edit/:id" component={EditCategory} />
        <Route exact path="/tag/edit/:id" component={EditTag} />

        {/*View*/}
        <Route exact path="/command/view/:id" component={ViewCommand} />
      </Switch>
    </Router>
  );
}

export default App;
