import React, { useState, useEffect } from "react";
import resources from "../../../services";
import config from "../../../constants";
import Button from "../../../ui/Button";

function Item(props) {
  const { id, quantity, cle, refresh, state } = props;
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [el, setEl] = useState(quantity);

  useEffect(() => {
    onLoad();
  }, [id]);

  function onLoad() {
    resources.article.readOne(id)
      .then((res) => {
        if (res.data) {
          setData(res.data);
        }
      })
      .catch(() => { });
  }
  function handleChange(e) {
    setEl(e);
  }

  function onChange() {
    setLoader(true);
    let truc = {
      quantity: el
    }
    resources.command.updateItem(cle, truc)
      .then(() => {
        setLoader(false);
        refresh();
      })
      .catch(() => {
        setLoader(false);
      });
  }

  function onDelete() {
    setLoader1(true);
    resources.command.deleteItem(cle)
      .then(() => {
        refresh();
        setLoader1(false);
      })
      .catch(() => {
        setLoader1(false);
      });
  }

  return (
    <tr>
      <td>
        {data.cover ? (
          <img
            src={config.server.localhost + "/uploads/products/" + data.cover}
            alt={data.name}
            width={50}
          />
        ) : (
          <span
            className="fa fa-square"
            style={{ color: "#096599", fontSize: 20 }}
          />
        )}
      </td>
      <td>
        {data.name} <br />
        {data.product_code}
      </td>


      <td>{data.prix}</td>
      <td>
        {state == 2 ? (
          <>{el}</>
        ) : (
          <div className="d-flex">
            <input
              className="form-control col mr-2"
              value={el}
              onChange={(e) => handleChange(e.target.value)}
            />
            <div class="btn-group-toggle">
              <Button
                className="btn  btn-primary "
                onClick={onChange}
                onLoad={loader}
              >
                Changer
              </Button>
              <Button
                className="btn btn-danger"
                onLoad={loader1}
                onClick={onDelete}
              >
                supprimer
              </Button>
            </div>
          </div>
        )}
      </td>
    </tr>
  );
}

export default Item;
