import Axios from "../Axios";

const article = {
    create(data) {
        return Axios.post('/products', data)
    },
    read(limit = 10, params = '', page = 1) {
        return Axios.get('/article?limit=' + limit + "&page=" + page + params)
    },
    online(limit = 10, page = 1) {
        return Axios.get('/article?is_active=1&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/article/' + id)
    },
    update(id, data) {
        return Axios.put('/products/' + id, data)
    },
    updateCover(id, data) {
        return Axios.post('/article/' + id, data)
    },
    delete(id) {
        return Axios.put('/article/' + id, {
            "active": false
        })
    },
    addStock(data) {
        return Axios.post("/stocks", data);
    },
    addSize(data) {
        return Axios.post("/size", data);
    },
    addColor(data) {
        return Axios.post("/color", data);
    },

}

export default article;
