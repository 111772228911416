import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { Link, useLocation, useHistory } from "react-router-dom";
import DataTable from "../../components/DataTable";
import resources from "../../services";
import config from '../../constants';
import Icon from '../../ui/Icon';
import Select from 'react-select'
import Button from "../../ui/Button";

function ArticlePage() {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();


    const [categories, setCategories] = useState([]),
        [tags, setTags] = useState([]),
        [vitrines, setVitrines] = useState([]),
        [category, setCategory] = useState(),
        [tag, setTag] = useState(),
        [vitrine, setVitrine] = useState(),
        history = useHistory(),
        [filter, setFilter] = useState(false);



    async function onGet() {

        let cus = await resources.category.read(50)
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCategories(a)
        }

        let tag = await resources.tag.read(50)
        if (tag.status === 200) {
            let a = []
            tag.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setTags(a)
        }

        let vit = await resources.vitrine.read(1000)
        if (vit.status === 200) {
            let a = []
            vit.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setVitrines(a)
        }
    }

    useEffect(() => {
        onGet();
    }, [])

    function filerArticle(e) {
        e.preventDefault();
        setFilter(true)
        let formData = new FormData(e.target);
        let el = '';
        if (formData.get('name')) {
            el = "&name=" + formData.get('name')
        }
        if (formData.get('id')) {
            el += "&id=" + formData.get('id')
        }
        if (formData.get('vitrine')) {
            el += "&vitrine=" + formData.get('vitrine')
        }
        if (formData.get('categorie')) {
            el += "&type=" + formData.get('categorie')
        }
        if (formData.get('product_code')) {
            el += "&product_code=" + formData.get('product_code')
        }
        if (formData.get('quantity')) {
            el += "&quantity=" + formData.get('quantity')
        }
        if (formData.get('tags')) {
            el += "&tags=" + formData.get('tags')
        }

        history.push("/articles?" + el)

    }

    function onChangeFilterLoader() {
        setFilter(false)
    }

    async function onUpdate(article, callback) {

        let data = {}
        if (article.is_active) {
            data.is_active = 0;
        } else {
            data.is_active = true;

        }
        let res = await resources.article.update(article.id, data)
        if (res.status === 200) {
            callback()
        }
    }



    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Articles</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item "><Link className="text-muted"
                                        to="/dashboard">Acceuil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Articles</li>
                                </ol>
                            </nav>
                            <Link className="btn btn-app-default " to="/article/create"><Icon name="add" color="white" /> Nouvelle article</Link>
                        </div>
                    </div>

                    <div className="col mt-5">
                        <div className="card card-body mb-3">
                            <form onSubmit={filerArticle}>
                                <div className="row">
                                    <div className="col">
                                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="ID" name="id" />
                                    </div>
                                    <div className="col">
                                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Nom" name="name" />
                                    </div>

                                    <div className="col">
                                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Code" name="product_code" />
                                    </div>

                                    <div className="col">
                                        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Stock" name="quantity" />
                                    </div>

                                    <div className="col">
                                        <Select name="vitrine" onChange={(e) => setVitrine(e.value)} options={vitrines} placeholder="Vendeur" />
                                    </div>
                                    <div className="col">
                                        <Select name="categorie" onChange={(e) => setCategory(e.value)} placeholder="Categorie" options={categories} />
                                    </div>
                                    {/* <div className="col">
                                        <Select name="tags" onChange={(e) => setTag(e.value)} placeholder="Tag" options={tags} />
                                    </div> */}
                                    <div className="col">
                                        <Button onLoad={filter} className="btn btn-app-default w-100" type="submit"> Appliquer</Button>
                                    </div>

                                </div>

                            </form>

                        </div>
                        <DataTable column={[
                            { label: "ID ", field: "id" },
                            {
                                label: "Cover", field: "cover", renderStyle: (data) => {
                                    return (<img src={config.server.localhost + "/uploads/products/" + data.cover} width="50" />)
                                }
                            },
                            { label: "Nom", field: "name" },
                            { label: "Prix d'achat", field: "price" },
                            { label: "Prix de vente", field: "prix" },
                            { label: "Code", field: "product_code" },
                            { label: "Référence", field: "product_reference" },
                            { label: "En stock", field: "quantity" },
                            {
                                label: "Vendeur", field: "vitrine", renderStyle: (data) => {
                                    return (<p>
                                        {data?.vitrine ? data?.vitrine?.name : "Trianta"}
                                    </p>)
                                }
                            },
                            { label: "Catégorie", field: "type", render: ["name"] },
                        ]}


                            filterLoader={onChangeFilterLoader}
                            element={"article"}
                            resource={resources.article}
                            action={(data, callback) => {
                                return <li><button type="button" onClick={() => onUpdate(data, callback)} class="btn btn-outline-success dropdown-item"> {data.is_active ? " Retirer du site" : "Mettre en ligne"} </button>
                                </li>
                            }}
                            update
                        />
                    </div>
                </main>
            </div>
        </div>

    </div>)
}

export default ArticlePage
