import Axios from "../Axios";

const report = {
    read(from = "", to = "") {
        return Axios.get("/rapports?from=" + from + "&to=" + to + "");
    },
    stock(from = "", to = "") {
        return Axios.get("/rapports?zero=true&from=" + from + "&to=" + to + "");
    },
    bestSeller(from = "", to = "") {
        return Axios.get("/rapports?sell=true&from=" + from + "&to=" + to + "");
    },

}

export default report;
