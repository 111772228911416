import React, { useState, useRef } from "react";
import Button from "../../ui/Button";
import resources from "../../services";
import moment from "moment";
import config from "../../constants";
import Select from 'react-select'

import { ComponentToPrint } from './ComponentToPrint';
import { useReactToPrint } from 'react-to-print';
import logo from "../../assets/logo.png"

function Sell() {
    const [data, setData] = useState([]);
    const [mem, setMem] = useState([])
    const [loader, setLoader] = useState(false);
    const [sellers, setSellers] = useState([])
    const [seller, setSeller] = useState()

    const [names, setNames] = useState([])
    const [name, setName] = useState()

    const [codes, setCodes] = useState([])
    const [code, setCode] = useState()

    const [refs, setRefes] = useState([])
    const [ref, setRef] = useState()

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    function onGet(e) {
        e.preventDefault();
        setLoader(true);
        resources.report.read(
            e.target.from.value && moment(e.target.from.value).format("DD-MM-YYYY"),
            e.target.to.value && moment(e.target.to.value).format("DD-MM-YYYY")
        )
            .then((res) => {
                let a = [];
                let b = [];
                let c = [];
                let d = [];
                let e = [];

                if (res.data) {
                    res.data.forEach((element) => {
                        a.push({
                            ...element,
                            date: element.createdAt.date,
                        });

                        b.push({ value: element.vitrine ? element.vitrine : null, label: element.vitrine ? element.vitrine : "Trianta Shop" })
                        c.push({ value: element.produit, label: element.produit })
                        d.push({ value: element.code, label: element.code })
                        e.push({ value: element.ref, label: element.ref })
                    });

                    let unique1 = b.filter((obj, index) => {
                        return index === b.findIndex(o => obj.value === o.value && obj.label === o.label);
                    });

                    let unique2 = c.filter((obj, index) => {
                        return index === c.findIndex(o => obj.value === o.value && obj.label === o.label);
                    });

                    let unique3 = d.filter((obj, index) => {
                        return index === d.findIndex(o => obj.value === o.value && obj.label === o.label);
                    });
                    let unique4 = e.filter((obj, index) => {
                        return index === e.findIndex(o => obj.value === o.value && obj.label === o.label);
                    });

                    setSellers(unique1)
                    setNames(unique2)
                    setCodes(unique3)
                    setRefes(unique4)
                    setData(a);
                    setMem(a)
                    setLoader(false);
                }
            })
            .catch(() => {
                setLoader(false);
            });

    }


    function onHanlderFilter(element, value) {
        let res = []
        if (element === 1) {

            res = mem.filter(el => el.vitrine === value.value);
        }

        if (element === 2) {
            res = mem.filter(el => el.produit === value.value);
        }

        if (element === 3) {
            res = mem.filter(el => el.code === value.value);
        }

        if (element === 4) {
            res = mem.filter(el => el.ref === value.value);
        }

        setData(res)
    }

    return (
        <div>
            <h4>Rapport de vente </h4>

            <form onSubmit={onGet} className="hidden-print">
                <div className="row" style={{ alignItems: "center" }}>
                    <div className="col">
                        <div className="form-group">

                            <input type="date" placeholder="Date de début" className="form-control" name="from" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <input type="date" placeholder="Date de fin" className="form-control" name="to" />
                        </div>
                    </div>

                    <div className="col">
                        <Button
                            type="submit"
                            className="btn btn-app-default w-100"
                            onLoad={loader}
                        >
                            envoyer
                        </Button>
                    </div>

                    <div className="col">
                        <button className="btn btn-app-default w-100 float-right" type="button"
                            onClick={handlePrint}><i className="bi bi-printer mr-1" />Imprimer
                        </button>
                    </div>


                </div>
            </form>
            <div className="row mt-5" style={{ alignItems: "center" }}>
                <div className="col">
                    <div className="form-group">
                        <Select placeholder="Vendeur" isClearable={true}  onChange={(e) => onHanlderFilter(1, e)} options={sellers} />
                    </div>

                </div>
                <div className="col">
                    <div className="form-group">
                        <Select placeholder="Nom" onChange={(e) => onHanlderFilter(2, e)} options={names} />
                    </div>

                </div>
                <div className="col">
                    <div className="form-group">
                        <Select placeholder="Code du produit" onChange={(e) => onHanlderFilter(3, e)} options={codes} />
                    </div>

                </div>
                <div className="col">
                    <div className="form-group">
                        <Select placeholder="Reference" onChange={(e) => onHanlderFilter(4, e)} options={refs} />
                    </div>

                </div>
            </div>
            <ComponentToPrint ref={componentRef}>

                <div className="d-flex justify-content-between card-header mt-3">
                    <div >
                        <img src={logo} width="150" />

                    </div>
                    <div>
                        <h1>Rapport de vente</h1>
                    </div>
                </div>


                <table className="table mt-3">
                    <thead className="table-dark">
                        <tr>

                            <th scope="col">Couverture</th>
                            <th scope="col">
                                Vendeur
                            </th>
                            <th scope="col">Nom du produit</th>
                            <th scope="col">Code du produit</th>
                            <th scope="col">Référence</th>
                            <th scope="col">Prix d'achat</th>
                            <th scope="col">Prix de vente</th>
                            <th scope="col">Quantité vendu</th>
                            <th scope="col">Quantité en stock</th>
                            <th scope="col">Date de vente</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.map((el, key) => {
                                return (
                                    <tr key={key}>
                                        <th><img
                                            src={config.server.web + "/uploads/products/" + el.image}
                                            alt={el.produit}
                                            width={100}
                                        /></th>
                                        <td>{el.vitrine ? el.vitrine : "Trianta Shop"}</td>
                                        <td>{el.produit}</td>
                                        <td>{el.code}</td>
                                        <td>{el.ref}</td>
                                        <td>{el.price}</td>
                                        <td>{el.prix}</td>
                                        <td>{el.qty}</td>
                                        <td>{el.stock}</td>
                                        <td>{el.date}</td>
                                    </tr>
                                )
                            })
                        }


                    </tbody>
                </table>

            </ComponentToPrint>
        </div>




    )
}

export default Sell
