import Axios from "../Axios";

const event = {
    create(data) {
        return Axios.post('/tevent', data)
    },
    read(limit = 10, page = 1) {
        return Axios.get('/tevent?active=1&limit=' + limit + "&page=" + page)
    },
    readOne(id) {
        return Axios.get('/tevent/' + id)
    },
    update(id, data) {
        return Axios.put('/tevent/' + id, data)
    },
    delete(id) {
        return Axios.put('/tevent/' + id, {
            "active": false
        })
    },
}

export default event;
