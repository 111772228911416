import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import Icon from "../ui/Icon";



export default function DataTable({ resource, column, deleteItem, update, action, element, view, filterLoader }) {

    let text = window.location.href;
    const myArray = text.split("?");
    const [data, setData] = useState([]),
        [detail, setDetail] = useState({}),
        [open, setOpen] = useState(false),
        [item, setItem] = useState(),
        [limit, setLimit] = useState(10),
        [page, setPage] = useState(1),
        [pages, setPages] = useState([]),
        [loader, setLoader] = useState(true),
        [currentPage, setCurrentPage] = useState(1)

    const handleClickOpen = (id) => {
        setOpen(true);
        setItem(id)
    };

    const handleClose = () => {
        setOpen(false);
    };


    async function onGet() {
        setLoader(true)
        let res = await resource.read(limit, myArray[1] && myArray[1], page)
        if (res.status === 200) {
            setData(res.data.items)
            setDetail(res.data)
            setPages(res.data.pagesInRange)
            setCurrentPage(res.data.currentPageNumber)
            setLoader(false)
            filterLoader()
        }
        setLoader(false)
        filterLoader()
    }


    async function onDelete() {
        let res = await resource.delete(item)
        if (res.status === 201) {
            onGet()
            handleClose()
        }
    }

    useEffect(() => {
        onGet()
    }, [limit, page, myArray[1]])

    return (
        <div>
            {/* <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Souhaitez-vous vraiment supprimé l'élément
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={onDelete} className="btn btn-danger">
                        Oui
                    </button>
                    <button onClick={handleClose} className="btn btn-secondary">
                        Non
                    </button>
                </DialogActions>
            </Dialog> */}

            {/* <div>
                <form>
                    {filterElement}
                </form>
            </div> */}
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            {column && column.map((i, k) => {
                                return <th scope="col" key={k}>{i.label}</th>
                            })}

                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.map((el, key) => {
                                return <tr className="bg-white" key={key}>
                                    {column && column.map((i) => {
                                        return <td>{!i.renderStyle ? !i.render ? el.hasOwnProperty(i.field) && el[i.field] : i.render.map((a, key) => {
                                            return el.hasOwnProperty(i.field) && el[i.field] ? el[i.field][a] : null
                                        }) : i.renderStyle(el)}</td>
                                    })}
                                    {
                                        action && (
                                            <td>
                                                <div className="dropdown">
                                                    <i id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                        aria-expanded="false" style={{ cursor: "pointer" }} >
                                                        <Icon name="verticalMore" />
                                                    </i>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        {
                                                            view && (<li><Link className="dropdown-item"
                                                                to={"/" + element + "/view/" + el.id}>
                                                                <Icon name="view" /> Visualiser</Link>
                                                            </li>)
                                                        }
                                                        {
                                                            update && (<li><Link className="dropdown-item"
                                                                to={"/" + element + "/edit/" + el.id}>
                                                                <Icon name="edit" /> Modifier</Link>
                                                            </li>)
                                                        }

                                                        {
                                                            deleteItem && (
                                                                <li><span className="dropdown-item" style={{ cursor: "pointer" }}
                                                                    onClick={() => handleClickOpen(el.id)}>
                                                                    <Icon name="delete" /> Supprimer</span>
                                                                </li>)
                                                        }
                                                        {action(el, onGet) && action(el, onGet)}
                                                    </ul>
                                                </div>
                                            </td>
                                        )
                                    }
                                </tr>
                            })
                        }

                    </tbody>
                </table>
            </div>

            {loader && <div className="row justify-content-center">
                <div className="col-lg-1">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>}



            <div className="d-flex justify-content-between">
                <div>
                    <select className="form-select form-select-sm" aria-label="Default select example"
                        onChange={(e) => setLimit(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                        <li className={detail?.previous != null ? "page-item" : "page-item disabled"}>
                            <span className="page-link" onClick={() => setPage(detail?.previous)}><span
                                aria-hidden="true">&laquo;</span></span>
                        </li>
                        {
                            pages && pages.map((el, key) => {
                                return <li className={currentPage == el ? "page-item active" : "page-item"} key={key}><a
                                    className="page-link"
                                    onClick={() => setPage(el)}>{el}</a>
                                </li>
                            })
                        }

                        <li className={detail?.next != null ? "page-item" : "page-item disabled"}>
                            <span className="page-link" onClick={() => setPage(detail?.next)}><span
                                aria-hidden="true">&raquo;</span></span>
                        </li>
                    </ul>
                </nav>

            </div>


        </div>
    )
}