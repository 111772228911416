import React, { useState, useEffect, useRef } from "react";
import resources from "../../services";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Button from "../../ui/Button";
import { useParams, Link } from "react-router-dom";
import logo1 from "../../assets/logo.png";
import Item from "./Item";
import ReactToPrint from "react-to-print";

let total = 0;
function ViewCommand() {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const componentRef = useRef();

    useEffect(() => {
        total = 0;
        onLoad();
    }, [id]);

    function onLoad() {
        resources.command.readOne(id)
            .then((res) => {
                if (res.data) {
                    let a = res.data.items;

                    for (let index = 0; index < a.length; index++) {
                        total += a[index].quantity * a[index].product.prix;
                    }
                    setData(res.data);
                    setLoader(false);
                }
            })
            .catch(() => { });
    }

    function onConfirm() {
        setLoading(true);

        let truc = {
            status: 2
        }
        resources.command.update(id, truc)
            .then((res) => {
                if (res.data.result) {
                    onLoad();
                    alert("Commande confirmer");
                } else {
                    setMessage(res.data.errors.format);
                    setError(true);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    function onCancel() {
        setLoading(true);

        let truc = {
            status: 3
        }
        resources.command.update(id, truc)
            .then((res) => {
                if (res.data.result) {
                    onLoad();
                    alert("Commande annuler");
                } else {
                    setMessage(res.data.errors.format);
                    setError(true);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    function onRefresh() {

        window.location.reload();
    }

    return (



        <div>
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />


                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>Commande</h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item "><Link className="text-muted"
                                            to="/dashboard">Accueil</Link></li>
                                        <li className="breadcrumb-item "><Link className="text-muted"
                                            to="/commands">Commandes</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Commande {id}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>

                        <div className="col mt-5">

                            <div className="mt-3 mb-5">
                                <div className="row justify-content-center">
                                    {loader ? (
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    ) : (
                                        <div className="col-lg-10">
                                            <div className="card">
                                                <div className="card-body" ref={componentRef} >
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <img src={logo1} width={100} />
                                                        </div>
                                                        <div>
                                                            <h6>
                                                                {data.bill_address.firstname}{" "}
                                                                {data.bill_address.lastname}
                                                            </h6>
                                                            <p>{data.bill_address.phone}</p>
                                                            <p>{data.bill_address.email}</p>
                                                            <p>
                                                                {data.bill_address.city},{" "}
                                                                {data.bill_address.address}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <table class="table mt-5">
                                                        <thead className="border-none">
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">Description</th>
                                                                <th scope="col">Prix unitaire($)</th>
                                                                <th scope="col">Quantité</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data &&
                                                                data?.items.map((el, key) => {
                                                                    return (
                                                                        <Item
                                                                            id={el.product.id}
                                                                            quantity={el.quantity}
                                                                            cle={el.id}
                                                                            refresh={onRefresh}
                                                                            state={data.status}
                                                                        />
                                                                    );
                                                                })}
                                                        </tbody>
                                                    </table>
                                                    <div className="d-flex justify-content-between">
                                                        <div />
                                                        <div>
                                                            <h6>Livraison : {total > 20 ? "0 $" : "3 $"}</h6>
                                                            <h6>
                                                                <b>TOTAL : {total > 20 ? total : total + 3} $</b>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                {error && (
                                                    <div
                                                        class="alert alert-warning alert-dismissible fade show"
                                                        role="alert"
                                                    >
                                                        <strong>{message}</strong>
                                                        <button
                                                            type="button"
                                                            class="close"
                                                            onClick={() => setError(false)}
                                                        >
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                )}
                                                <div class="card-footer">
                                                    {data.status == 1 ? (
                                                        <div className="d-flex justify-content-between">
                                                            <Button class="btn btn-danger text-white  " onClick={onCancel}>
                                                                Rejeter
                                                            </Button>

                                                            <Button
                                                                class="btn btn-app-default "
                                                                onLoad={loading}
                                                                onClick={onConfirm}
                                                            >
                                                                Confirmer
                                                            </Button>
                                                        </div>
                                                    ) : (
                                                        data.status == 2 && (
                                                            <div className="d-flex justify-content-between">
                                                                <div />
                                                                <ReactToPrint
                                                                    trigger={() => {
                                                                        return (
                                                                            <Button class="btn btn-app-default">
                                                                                Imprimer
                                                                            </Button>
                                                                        );
                                                                    }}
                                                                    content={() => componentRef.current}
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </main>
                </div>
            </div>

        </div>


    );
}

export default ViewCommand;
