import React, { useState, useRef } from "react";
import Button from "../../ui/Button";
import resources from "../../services";
import moment from "moment";
import config from "../../constants";

import { ComponentToPrint } from './ComponentToPrint';
import { useReactToPrint } from 'react-to-print';
import logo from "../../assets/logo.png"


function Stock() {
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);


    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function onGet(e) {
        e.preventDefault();
        setLoader(true);
        resources.report.stock(e.target.from.value && moment(e.target.from.value).format("DD-MM-YYYY"),
            e.target.to.value && moment(e.target.to.value).format("DD-MM-YYYY"))
            .then((res) => {
                let a = [];
                if (res.data) {
                    res.data.forEach((element) => {
                        a.push({
                            ...element,
                            date: element.createdAt.date,
                        });
                    });
                    setData(a);
                    setLoader(false);
                }
            })
            .catch(() => {
                setLoader(false);
            });

    }

    return (
        <div>
            <h4>Rapport de stock 0</h4>

            <form onSubmit={onGet} className="hidden-print">
                <div className="row" style={{ alignItems: "center" }}>
                    <div className="col">
                        <div className="form-group">
                            <input type="date" className="form-control" name="from" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <input type="date" className="form-control" name="to" />
                        </div>
                    </div>


                    <div className="col">
                        <Button
                            type="submit"
                            className="btn btn-app-default w-100"
                            onLoad={loader}
                        >
                            envoyer
                        </Button>
                    </div>
                    <div className="col">
                        <button className="btn btn-app-default w-100 float-right" type="button"
                            onClick={handlePrint}><i className="bi bi-printer mr-1" />Imprimer
                        </button>
                    </div>

                </div>
            </form>

            <ComponentToPrint ref={componentRef}>
                <div className="d-flex justify-content-between card-header mt-3">
                    <div >
                        <img src={logo} width="150" />

                    </div>
                    <div>
                        <h1>Rapport de stock</h1>
                    </div>
                </div>

                <table className="table mt-3">
                    <thead className="table-dark">
                        <tr>

                            <th scope="col">Couverture</th>
                            <th scope="col">Vendeur</th>
                            <th scope="col">Nom du produit</th>
                            <th scope="col">Code du produit</th>
                            <th scope="col">Référence h</th>
                            <th scope="col">Prix d'achat</th>
                            <th scope="col">Prix de vente</th>
                            <th scope="col">En stock</th>
                            <th scope="col">Date de création</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.map((el, key) => {
                                return (
                                    <tr key={key}>
                                        <th><img
                                            src={config.server.web + "/uploads/products/" + el.image}
                                            alt={el.produit}
                                            width={100}
                                        /></th>
                                        <td>{el.vitrine ? el.vitrine : "Trianta Shop"}</td>
                                        <td>{el.produit}</td>
                                        <td>{el.code}</td>
                                        <td>{el.ref}</td>
                                        <td>{el.price}</td>
                                        <td>{el.prix}</td>
                                        <td>{el.stock}</td>
                                        <td>{el.date}</td>
                                    </tr>
                                )
                            })
                        }


                    </tbody>
                </table>
            </ComponentToPrint>

        </div>
    )
}

export default Stock
