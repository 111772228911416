import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable";
import resources from "../../services";
import config from '../../constants';

export default function CommandPage() {
    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                    <div>
                        <h3>Commandes</h3>
                        <div className="d-flex justify-content-between">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item "><Link className="text-muted"
                                        to="/dashboard">Accueil</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Commandes</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="col mt-5">
                        <DataTable column={[
                            {
                                label: "Clients", field: "bill_address", renderStyle: (data) => {
                                    return <p>{data.bill_address.lastname} {data.bill_address.firstname}</p>
                                }
                            },
                            { label: "Ville", field: "bill_address", render: ["city"] },
                            { label: "Numéro de téléphone", field: "bill_address", render: ["phone"] },
                            {
                                label: "Status", field: "status", renderStyle: (data) => {
                                    return <p>{data.status === 1 && <span class="badge bg-warning text-dark">en attente</span>}
                                        {data.status === 2 && <span class="badge bg-success">livrer</span>}
                                        {data.status === 3 && <span class="badge bg-danger">annuler</span>}
                                    </p>
                                }
                            },
                            {
                                label: "Date", field: "createdAt", renderStyle: (data) => {
                                    return <p>
                                        {new Date(data.createdAt).toLocaleDateString()}
                                    </p>
                                }
                            }
                        ]}
                            action={() => { }}
                            view
                            element={"command"}
                            resource={resources.command}
                        />
                    </div>
                </main>
            </div>
        </div>

    </div>)
}


