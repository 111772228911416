import React, { useState, useEffect } from 'react'
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Card from "../../components/Card";
import resources from "../../services";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip
} from "recharts";
import Icon from '../../ui/Icon';


function Dashboard() {

    const [customers, setCustomers] = useState(0),
        [invoices, setInvoices] = useState(0),
        [estimations, setEstimations] = useState(0),
        [article, setArticle] = useState(0),
        [chart, setChart] = useState([]),
        [period, setPeriod] = useState('week')

    async function onGet() {
        let cust = await resources.customer.read()
        if (cust.status === 200) {
            setCustomers(cust.data.totalCount)
        }

        let inv = await resources.vitrine.read()
        if (inv.status === 200) {
            setInvoices(inv.data.totalCount)
        }

        let est = await resources.command.pending()
        if (est.status === 200) {
            setEstimations(est.data.totalCount)
        }

        let prod = await resources.article.online()
        if (prod.status === 200) {
            setArticle(prod.data.totalCount)
        }

        let formData = new FormData()
        formData.append("period", period)
        let chart = await resources.chart.create(formData)
        if (chart.status === 200) {
            setChart(chart.data)
        }
    }

    useEffect(() => {
        onGet()
    }, [period])


    return (<div>
        <Header />
        <div className="container-fluid">
            <div className="row">
                <Sidebar />
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <div className="row mt-4">
                        <div className="col">
                            <Card value={customers} name="Clients enregistré"
                                icon={<Icon name="people" color="black" />} />
                        </div>
                        <div className="col">
                            <Card value={invoices} name="Virtines actif"
                                icon={<Icon name="shop" color="blue" />} />
                        </div>
                        <div className="col">
                            <Card value={estimations} name="Commandes en attente"
                                icon={<Icon name="receipt" color="red" />} />
                        </div>
                        <div className="col">
                            <Card value={article} name="Article en ligne"
                                icon={<Icon name="star" color="green"/>} />
                        </div>

                    </div>

                    <div className="row mt-4">
                        <div className="col">
                            <div className="card">
                                <div className="d-flex justify-content-between m-3">
                                    <h6 className="fw-bold"><Icon /> Ventes, Vitrines & Clients</h6>
                                    <div>
                                        <select className="form-select" onChange={(e) => setPeriod(e.target.value)}>
                                            <option value="week">Semaine</option>
                                            <option value="month">Mois</option>
                                        </select>
                                    </div>

                                </div>
                                <div className="card-body" style={{
                                    height: 440,
                                    width: "100%"
                                }}>
                                    <ResponsiveContainer>
                                        <LineChart
                                            data={chart}
                                            margin={{
                                                top: 16,
                                                right: 16,
                                                bottom: 0,
                                                left: 24,
                                            }}
                                        >


                                            <XAxis dataKey="time" stroke={"black"} />
                                            <YAxis stroke={"black"} />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Line
                                                type="monotone"
                                                dataKey="Vitrines"
                                                stroke={"tomato"}

                                            />

                                            <Line
                                                type="monotone"
                                                dataKey="Commandes"
                                                stroke={"red"}

                                            />
                                            <Line
                                                type="monotone"
                                                dataKey="Clients"
                                                stroke={"green"}

                                            />

                                            <Tooltip />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </div>
        </div>

    </div>)
}

export default Dashboard
