import React, {useState, useEffect} from 'react'
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import $ from "jquery";
import {Link, useHistory, useParams} from "react-router-dom";
import resources from "../../services";
import Button from "../../ui/Button";
import Select from 'react-select'
import Icon from '../../ui/Icon';


function EditArticle() {

    const [loader, setLoader] = useState(false),
        history = useHistory(),
        [categories, setCategories] = useState([]),
        [tags, setTags] = useState([]),
        [vitrines, setVitrines] = useState([]),
        [category, setCategory] = useState(),
        [tag, setTag] = useState(),
        [vitrine, setVitrine] = useState(),
        {id} = useParams(),
        [data, setData] = useState({}),
        [display, setDisplay] = useState(0),
        [quantity, setQuantity] = useState(0),
        [loading, setLoading] = useState(false),
        [size, setSize] = useState(),
        [color, setColor] = useState(),
        [sizes, setSizes] = useState([]),
        [colors, setColors] = useState([]),
        [selectedSize, setSelectedSize] = useState(),
        [selectedColor, setSelectedColor] = useState({})

    async function onGet() {


        let tag = await resources.tag.read(10000)
        if (tag.status === 200) {
            let a = []
            tag.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setTags(a)
        }

        let vit = await resources.vitrine.read(1000)
        if (vit.status === 200) {
            let a = []
            vit.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setVitrines(a)
        }

        let res = await resources.article.readOne(id)
        if (res.status === 200) {
            setData(res.data)

            if (res.data.colors) {
                let a = []
                res.data.colors.map((k) => {
                    a.push({
                        value: k.id,
                        label: k.value
                    })
                })
                setColors(a)
            }

            if (res.data.sizes) {
                let a = []
                res.data.sizes.map((k) => {
                    a.push({
                        value: k.id,
                        label: k.value
                    })
                })
                setSizes(a)
            }

        }

        let cus = await resources.category.read(50)
        if (cus.status === 200) {
            let a = []
            cus.data.items.forEach((el) => {
                a.push({
                    value: el.id,
                    label: el.name,
                })
            })
            setCategories(a)
        }

    }

    useEffect(() => {
        onGet();
    }, [id])

    /**
     * @param {React.FormEvent<HTMLFormElement>} e
     */
    async function onUpdate(e) {
        e.preventDefault()
        setLoader(true)


        let formData = new FormData(e.target)

        formData.delete("quantity")
        if (vitrine) {
            formData.append("vitrine", vitrine)
        }

        if (category) {
            formData.append("type", category)
        }


        if (tag) {
            let a = []
            a.push(tag)
            formData.append("tags", JSON.stringify(a))
        }


        if (formData.get("coverFile").name) {
            await resources.article.updateCover(id, formData)
        }
        formData.delete("coverFile")
        let object = {};
        formData.forEach((value, key) => object[key] = value);


        let res = await resources.article.update(id, object)
        if (res.status === 201) {
            history.goBack()
        } else {
            setLoader(true)
        }
        setLoader(false)
    }

    async function addStock(e) {
        e.preventDefault();
        setLoading(true)
        let formData = new FormData();
        formData.append("size", selectedSize);
        formData.append("color", selectedColor.value);
        formData.append("quantity", quantity);
        formData.append("total", data.prix * quantity);
        formData.append("price", data.prix);
        formData.append("product", id);

        let res = await resources.article.addStock(formData);
        if (res.data.result) {
            onGet()
            setLoading(false)
        }
        setLoading(false)
    }


    async function addSize(e) {
        e.preventDefault();
        setLoading(true)
        let formData = new FormData();
        formData.append("value", size);
        formData.append("product", id);

        let res = await resources.article.addSize(formData);
        if (res.status === 201) {
            onGet()
            setLoading(false)
        }
        setLoading(false)
    }

    async function addColor(e) {
        e.preventDefault();
        setLoading(true)
        let formData = new FormData();
        formData.append("value", color);
        formData.append("product", id);

        let res = await resources.article.addColor(formData);
        if (res.status === 201) {
            onGet()
            setLoading(false)
        }
        setLoading(false)
    }


    return (<div>
        <Header/>
        <div className="container-fluid">
            <div className="row">
                <Sidebar/>
                <form onSubmit={onUpdate}>
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-2">
                        <div>
                            <h3>Article {id}</h3>
                            <div className="d-flex justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link className="text-muted"
                                                                              to="/dashboard">Acceuil</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page"><Link
                                            className="text-muted" to="/articles">Articles</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Article {id}</li>
                                    </ol>
                                </nav>
                                {
                                    display === 0 && <Button className="btn btn-app-default btn-sm"
                                                             type="submit" onLoad={loader}><Icon name="save"
                                                                                                 color="white"/> Enregistré</Button>
                                }
                                {
                                    display === 1 && <button className="btn btn-app-default btn-sm"
                                                             type="button" data-bs-toggle="modal"
                                                             data-bs-target="#exampleModal"><Icon name="stock"
                                                                                                  color="white"/> Ajouter
                                        en stock</button>
                                }
                                {
                                    display === 2 && <button className="btn btn-app-default btn-sm"
                                                             type="button" data-bs-toggle="modal"
                                                             data-bs-target="#exampleModal2"><Icon name="stock"
                                                                                                   color="white"/> Ajouter
                                        une taille</button>
                                }
                                {
                                    display === 3 && <button className="btn btn-app-default btn-sm"
                                                             type="button" data-bs-toggle="modal"
                                                             data-bs-target="#exampleModal3"><Icon name="stock"
                                                                                                   color="white"/> Ajouter
                                        une couleur</button>
                                }

                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 mb-3">

                            <ul class="nav nav-tabs" id="myTab" role="tablist">

                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="profile-tab" data-bs-toggle="tab"
                                            data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                                            aria-selected="true" onClick={() => setDisplay(0)}>Informations
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab"
                                            data-bs-target="#contact" type="button" role="tab" aria-controls="contact"
                                            aria-selected="false" onClick={() => setDisplay(1)}>Stock
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="size-tab" data-bs-toggle="tab" data-bs-target="#size"
                                            type="button" role="tab" aria-controls="size" aria-selected="false"
                                            onClick={() => setDisplay(2)}>Taille
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="color-tab" data-bs-toggle="tab" data-bs-target="#color"
                                            type="button" role="tab" aria-controls="color" aria-selected="false"
                                            onClick={() => setDisplay(3)}>Couleur
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="other-tab" data-bs-toggle="tab" data-bs-target="#other"
                                            type="button" role="tab" aria-controls="other" aria-selected="false"
                                            onClick={() => setDisplay(4)}>Autre propriété
                                    </button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">

                                <div class="tab-pane fade show active" id="profile" role="tabpanel"
                                     aria-labelledby="profile-tab">
                                    <div className="col-lg-6 mt-3">
                                        <div className="card">
                                            <div className="card-body">

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Image de
                                                        couverture </label>
                                                    <input type="file"
                                                           class="form-control"
                                                           id="exampleInputEmail1"
                                                           aria-describedby="emailHelp"
                                                           name="coverFile"/>
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Nom ou
                                                        marque <span
                                                            style={{color: "red"}}>*</span></label>
                                                    <input type="text" required className="form-control"
                                                           id="exampleInputEmail1"
                                                           name="name" defaultValue={data?.name}/>
                                                </div>


                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                           className="form-label">code <span
                                                        style={{color: "red"}}>*</span></label>
                                                    <input type="text" required className="form-control"
                                                           id="exampleInputEmail1"
                                                           name="product_code" defaultValue={data?.product_code}/>
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Prix de
                                                        vente <span
                                                            style={{color: "red"}}>*</span></label>
                                                    <input type="text" required className="form-control"
                                                           id="exampleInputEmail1"
                                                           name="prix" defaultValue={data?.prix}/>
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Prix
                                                        d'achat <span
                                                            style={{color: "red"}}>*</span></label>
                                                    <input type="text" required className="form-control"
                                                           id="exampleInputEmail1"
                                                           name="price" defaultValue={data?.price}/>
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                           className="form-label">Référence <span
                                                        style={{color: "red"}}>*</span></label>
                                                    <input type="text" required className="form-control"
                                                           id="exampleInputEmail1"
                                                           name="product_reference"
                                                           defaultValue={data?.product_reference}/>
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlTextarea1"
                                                           className="form-label">Description <span
                                                        style={{color: "red"}}>*</span></label>
                                                    <textarea className="form-control" required
                                                              id="exampleFormControlTextarea1"
                                                              rows="3" name="description"
                                                              defaultValue={data?.description}/>
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                           className="form-label">Categorie <span
                                                        style={{color: "red"}}>*</span></label>

                                                    {
                                                        data?.type && <Select onChange={(e) => setCategory(e.value)}
                                                                              options={categories} defaultValue={
                                                            {label: data?.type?.name, value: data?.type?.id}
                                                        }/>
                                                    }

                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                           className="form-label">Tag <span
                                                        style={{color: "red"}}>*</span></label>
                                                    {
                                                        data?.tags &&
                                                        <Select onChange={(e) => setTag(e.value)} options={tags}
                                                                defaultValue={
                                                                    {
                                                                        label: data?.tags[0]?.name,
                                                                        value: data?.tags[0]?.id
                                                                    }
                                                                }/>
                                                    }

                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleInputEmail1"
                                                           className="form-label">Vendeur</label>
                                                    <Select onChange={(e) => setVitrine(e.value)} options={vitrines}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">

                                    <div class="modal fade" id="exampleModal" tabindex="-1"
                                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">


                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Ajouter en stock</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">

                                                    <div className="mb-3">
                                                        <label htmlFor="exampleInputEmail1"
                                                               className="form-label">Couleur {selectedColor ? <Button
                                                            class="btn  btn-sm"
                                                            style={{
                                                                width: 10,
                                                                height: 10,
                                                                borderRadius: 5,
                                                                backgroundColor: selectedColor.label

                                                            }}/> : " "} </label>
                                                        <br/>
                                                        <idv>
                                                            {
                                                                colors && colors.map((el, key) => {
                                                                    return <Button
                                                                        onClick={() => setSelectedColor(el)}
                                                                        key={key}
                                                                        type="button"
                                                                        style={{
                                                                            width: 30,
                                                                            height: 30,
                                                                            borderRadius: 15,
                                                                            backgroundColor: el.label,

                                                                        }}
                                                                        class="btn  btn-sm"/>
                                                                })
                                                            }


                                                        </idv>


                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="exampleInputEmail1"
                                                               className="form-label">Taille </label>
                                                        <Select options={sizes} onChange={(e) => console.log(e)}/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleInputEmail1" className="form-label">Quantité<span
                                                            style={{color: "red"}}>*</span></label>
                                                        <input type="number" className="form-control"
                                                               id="exampleInputEmail1"
                                                               name="quantity"
                                                               onChange={(e) => setQuantity(e.target.value)}/>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label htmlFor="exampleInputEmail1" className="form-label">Prix
                                                            de vente</label>
                                                        <input type="text" className="form-control" disabled
                                                               value={data?.prix + " $"}/>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleInputEmail1"
                                                               className="form-label">Total </label>
                                                        <input type="text" className="form-control" disabled
                                                               value={data.prix * quantity + " $"}/>
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary btn-sm"
                                                            data-bs-dismiss="modal">Annuler
                                                    </button>
                                                    <Button type="button" class="btn btn-primary btn-sm"
                                                            onLoad={loading} onClick={addStock}>Enregistré</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th>Prix de vente</th>
                                            <th>Quantité</th>
                                            <th>Total</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.stockHistorics?.map((el, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{el.price} $</td>
                                                    <td>{el.quantity} </td>
                                                    <td>{el.total} $</td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>


                                <div class="tab-pane fade" id="size" role="tabpanel" aria-labelledby="size-tab">

                                    <div class="modal fade" id="exampleModal2" tabindex="-1"
                                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">


                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Ajouter une
                                                        taille</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleInputEmail1" className="form-label">Valeur<span
                                                            style={{color: "red"}}>*</span></label>
                                                        <input type="text" className="form-control"
                                                               id="exampleInputEmail1"

                                                               onChange={(e) => setSize(e.target.value)}/>
                                                    </div>


                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary btn-sm"
                                                            data-bs-dismiss="modal">Annuler
                                                    </button>
                                                    <Button type="button" class="btn btn-primary btn-sm"
                                                            onLoad={loading} onClick={addSize}>Enregistré</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th>Taille</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.sizes?.map((el, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{el.value}</td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>


                                <div class="tab-pane fade" id="color" role="tabpanel" aria-labelledby="color-tab">

                                    <div class="modal fade" id="exampleModal3" tabindex="-1"
                                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">


                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Ajouter une
                                                        couleur</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleInputEmail1" className="form-label">Valeur<span
                                                            style={{color: "red"}}>*</span></label>
                                                        <input type="color" class="form-control form-control-color"
                                                               id="exampleColorInput"
                                                               onChange={(e) => setColor(e.target.value)}
                                                               title="Choose your color"/>
                                                    </div>


                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary btn-sm"
                                                            data-bs-dismiss="modal">Annuler
                                                    </button>
                                                    <Button type="button" class="btn btn-primary btn-sm"
                                                            onLoad={loading} onClick={addColor}>Enregistré</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th>Couleur</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.colors?.map((el, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        <div style={{
                                                            width: 30,
                                                            height: 30,
                                                            borderRadius: 15,
                                                            backgroundColor: el.value
                                                        }}/>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>


                                <div class="tab-pane fade" id="other" role="tabpanel" aria-labelledby="other-tab">

                                    <div class="modal fade" id="exampleModal3" tabindex="-1"
                                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog">


                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Ajouter une
                                                        propriété</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleInputEmail1" className="form-label">Valeur<span
                                                            style={{color: "red"}}>*</span></label>
                                                        <input type="number" className="form-control"
                                                               id="exampleInputEmail1"

                                                               onChange={(e) => setQuantity(e.target.value)}/>
                                                    </div>


                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary btn-sm"
                                                            data-bs-dismiss="modal">Annuler
                                                    </button>
                                                    <Button type="button" class="btn btn-primary btn-sm"
                                                            onLoad={loading} onClick={addStock}>Enregistré</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {/* <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Couleur</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.stockHistorics?.map((el, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{el.price} $</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table> */}
                                </div>


                            </div>


                        </div>
                    </main>
                </form>
            </div>
        </div>

    </div>)
}

export default EditArticle
